<template>
    <section class="retur">

    </section>
</template>

<script>
    export default {
        name: "retur",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {};
        },
        methods: {},
        mounted() {},
        watch:{}
    }
</script>

<style lang="scss" scoped>
    .retur {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>